.flex {
  display: flex;

  &.align-top {
    align-items: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }
}
