.menu-user-logout.ui-menu {
  background: $white;
  color: $grey-dark;
  padding: 0;
  width: 140px;

  .ui-menuitem {
    margin: 0;
  }

  .ui-menuitem-link {
    font-size: 16px;
    color: $grey-dark;
    border: none;
    padding: 6px;

    &:hover {
      background: $grey-lightest;
    }
  }
}