.input-error-container {
  margin-bottom: 20px;
}

input.default {
  color: $grey-dark;
  font-size: 16px;
  font-family: $font-family-main;
  border: 1px solid $grey-dark;
  background-color: $grey-lightest;
  border-radius: 3px;
  padding: 7px 12px;

  &:focus {
    outline: none;
  }

  &.error, &.error:focus {
    @extend %error;
  }
}

label.default {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: $font-weight-semibold;
}

.input-subtext {
  font-size: 14px;
  font-style: italic;
}