/*****
* styles common to the authorization focused pages
* /login and /confirm-user
******/

.auth-title {
  font-weight: $font-weight-bold;
  text-align: center;
  margin: 0 0 57px 0;

  i {
    vertical-align: -2px;
    font-size: 17px;
  }
}
