.branded {
  color: $brand-green;
}

.bold {
  font-weight: $font-weight-bold;
}

.pointer {
  cursor: pointer;
}

/**
* Setting specific widths for certain elements within the app
**/

.width-410px {
  width: 410px;
}

.width-420px {
  width: 420px;
}

.width-100 {
  width: 100%;
}

.width-75 {
  width: 75%;
}

.width-25 {
  width: 25%;
}
