app-lang-setting {
  margin-left: -10px;
}

.main-content {
  
  margin: 20px 60px 0px 60px;

  .section-centered {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
