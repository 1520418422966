.dropdown-language-setting.ui-dropdown {
  padding: 7px 1px 7px 10px;
  border-radius: 3px;

  app-faux-radio-btn {
    vertical-align: middle;
    margin-right: 8px;
  }

  .ui-dropdown {
    cursor: default;
  }

  .ui-dropdown-trigger {
    display: none;
  }

  .ui-dropdown-label {
    color: $brand-green;
  }
  &.ui-dropdown:hover, &.ui-dropdown-open {	
    background-color: $language-setting-hover;	     
    }



  .ui-inputtext {
    padding-left: 0;
    padding-right: inherit;
  }

  .ui-dropdown-panel {
    min-width: 200px;
    background-color: $grey-lightest;
    border: 1px solid $grey-light;
    border-radius: 3px;
    box-shadow: none;
    right: 0;
    left: unset !important;

    ul.ui-dropdown-items {
      margin: 0;
      padding: 10px;

      li {
        &>div:not(.branded):hover {
          text-decoration: underline;
        }

        &.ui-dropdown-item {
          border-bottom: 1px solid $grey-light;
          padding: 8px 0;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
}
