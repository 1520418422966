$font-family-main: 'Open Sans', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$grey-lightest: #f8f8f8;
$grey-light: #979797;
$grey-medium: #777777;
$grey-dark: #565656;

$grey-border: #e5e5e5;

$brand-green: #148900;
$green-light: #6FC96F;
$warn-red: #d54230;
$white: #fff;
$messages-background: #FFFCD6;

$language-setting-hover: rgba(20, 137, 0, 0.05);

$dialog-box-shadow: rgba(86,86,86,0.25);

%error{
  border: 1px solid $warn-red;
  outline: $warn-red auto 3px;
  outline-offset: -2px;
}
