$columns: (
  'date-column': 180px,
  'action-column': 220px
);

.ses-dashboard-table table {
  thead.ui-table-thead tr th {
    @each $class, $width in $columns { &.#{$class} { width: $width; } }
  }

  tbody.ui-table-tbody tr td {
    @each $class, $width in $columns { &.#{$class} { width: $width; } }
  }
}
