.ses-message {
    .fa-check {
        font-size: 24px;
        color: $brand-green;
        &::before{
          content: "\f05d";
       }
  }

  .ui-messages {
      background: $messages-background;
      border-color: $messages-background;
      color: $grey-dark;
      font-size: 20px;
      margin: 0;
      border-width: 0 16px;
      .ui-messages-close {
        padding-top: 12px;
        color: $grey-dark;
      }
      .ui-messages-summary {
        font-weight: $font-weight-regular;
      }
  }

  span.ui-messages-icon.pi.pi-times {
    color: $warn-red;
  }

  span.ui-messages-icon.pi.pi-check {
    color: $brand-green;
  }
}
