.ses-table {
  .ui-table-thead {
    background-color: $grey-lightest;
    border-bottom: 1px solid $grey-border;
    color: $grey-medium;
  }
  .ui-table-thead > tr > th {
    padding: 10px 0 10px 10px;
    font-weight: $font-weight-regular;
    text-align: left;
  }
  .ui-table-tbody > tr > td {
    padding: 35px 5px 35px 10px;
    border-bottom: 1px solid $grey-border;
    color: $grey-dark;
  }
  .ui-table-tbody > tr > td.ui-table-empty-message {
    font-size: 36px;
    text-align: center;
    color: $grey-medium;
    font-weight: $font-weight-light;
    border-bottom: 1px solid $grey-light;
  }

  .ui-widget-overlay {
    background-color: $grey-light;
    opacity: 0;
  }

  .ui-table-loading-content {
    color: $grey-dark;
  }
}

/*
  hides an empty table message while a table is loading
  make sure to wrap the empty message in a span with the
  hide-while-loading-message class
*/
.ses-table .ui-table-loading ~ .ui-table-wrapper table tbody tr td.ui-table-empty-message span {
  display: none;
}