.ses-dialog.ui-dialog {
  background-color: $white;
  box-shadow: 0 1px 6px 3px $dialog-box-shadow;

  .ui-dialog-titlebar {
    background-color: $warn-red;
    padding: 12px 0;
    text-align: center;

    .ui-dialog-title {
      font-size: 18px;
      font-weight: $font-weight-bold;
      color: $white;
    }

    p-header {
      &>span:first-of-type {
        vertical-align: 3px;
        margin-left: 10px;
      }
    }

    i {
      font-size: 32px;
    }
  }

  .ui-dialog-content {
    font-weight: $font-weight-semibold;
    padding: 30px;
    line-height: 18px;
  }

  .ui-dialog-footer {
    padding: 15px 30px 30px 30px;
  }

  span.pi.pi-times {
    display: none;
  }

  button {
    margin-left: 16px;
  }
}
