.ses-dropdown {
    color: $grey-dark;
    border: 1px solid $grey-dark;
    background-color: $grey-lightest;
    border-radius: 3px;
    min-width: 250px;
    padding: 3px 0 3px 10px;

    &.error {
      @extend %error;
    }

    ul.ui-dropdown-items {
      li.ui-dropdown-item:hover {
        background-color: darken($grey-lightest, 8%);
      }
    }

    .ui-dropdown-panel {
        background-color: $grey-lightest;
        border: 1px solid $grey-dark;
        margin: 3px 0;
        border-radius: 3px;
    }

    .ui-dropdown-item-empty {
      display: none;
    }
    .ui-shadow {
        box-shadow: none;
    }
    .ui-dropdown-trigger {
      right: 5px;

      .ui-dropdown-trigger-icon {
        top: 56%;
      }
    }
}
