html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $font-family-main;
    color: $grey-dark;
    font-weight: $font-weight-regular;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ul.style-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**************
**  BUTTONS  **
***************/

button {
  font-family: $font-family-main;
  font-size: 16px;
  font-weight: $font-weight-semibold;
  color: $white;
  background-color: $brand-green;
  height: 40px;
  min-width: 130px;
  border: none;
  border-radius: 3px;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: darken($brand-green, 3%);
  }

  &.button-cancel {
    background-color: $grey-medium;

    &:hover {
      background-color: darken($grey-medium, 4%);
    }
  }

  &.btn-styleless {
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    border: none;
    background: inherit;
  }
}
